<template>
  <div class="brand">
    <div class="text">
      <!-- <img
        src="../../assets/img/about/gushi/01.png"
        alt=""
      > -->
      <div class="textbox">
        <p class="ename">Brand Story</p>
        <p class="nname">酵色·品牌故事</p>
        <p class="joo">Joocyee[ˈdʒuːsi]</p>
        <p class="initr1">adj. 感性浪漫的；有设计感的；实用质感的；追寻艺术的；自由独立的</p>
        <p class="initr2">酵色隶属于上海橘宜化妆品有限公司，为中国新生代女性而生。我们认为彩妆不只是简单的快消品，它接近时尚行业，可以是一种生活艺术和美学的载体。</p>
        <p class="initr2">在实用基础上，我们希望借由探索不同主题在彩妆上的运用，创造出更具潮流和艺术感的“设计师彩妆”。</p>

      </div>

    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.brand {
  width: 100%;
  height: calc(100vh - 7vh);
  background: url("../../assets/img/about/gushi/1.jpg") no-repeat center center;
  background-size: cover;
  .text {
    position: relative;
    z-index: 100;
    width: 40.563rem;
    height: 15.563rem;
    top: 50%;
    left: 3.125rem;
    transform: translateY(-50%);
    img {
      width: 40.563rem;
      height: 15.563rem;
    }
    .textbox {
      position: absolute;
      top: 0;
      left: 0.355rem;
      .ename {
        color: #fff;
        font-size: 4.55rem;
        font-family: Arachne;
        line-height: 1.15;
      }
      .nname {
        color: #fff;
        font-size: 1.7rem;
        text-indent: 0.6rem;
        letter-spacing: 5px;
        margin-bottom: 1.6rem;
      }
      .joo {
        color: #fff;
        text-indent: 0.6rem;
        font-size: 0.8rem;
        line-height: 1.6;
      }
      .initr1 {
        color: #fff;
        text-indent: 0.6rem;
        font-size: 0.7rem;
        letter-spacing: 1px;
        margin-bottom: 1.6rem;
      }
      .initr2 {
        color: #fff;
        text-indent: 0.6rem;
        font-size: 0.7rem;
        letter-spacing: 1px;
        line-height: 1.7;
        white-space: nowrap;
      }
    }
  }
}
</style>